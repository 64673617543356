import React, { useState, useEffect, useRef } from "react";
import getVehicleAccessories from '../../../../src/services/accessoriesService';
import { Accordion } from "../../../components/molecules/Accordion.js";
import "../../../styles/scss/details-v2/accessories.scss";
import AccessoriesAccordionContent from "./accessoriesAccordionContent";
import Skeleton from "react-loading-skeleton";
import { accordionClick } from "../vehicleDetails.analytics";
import { getVPTooltips } from "../../VPRouter/VPUtils.js";
import applyMarkdown from "../../utils/applyMarkdown";

const Accessories = (props) => {
    const [interiorAccessories, setInteriorAccessories] = useState([]);
    const [exteriorAccessories, setExteriorAccessories] = useState([]);
    const [performanceAccessories, setPerformanceAccessories] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tooltips, setTooltips] = useState({});
    const collapseAccordionRef = useRef(props?.title?.toLowerCase());

    useEffect(() => {
        const modelName = props?.modelName.toLowerCase();
        const modelYear = props?.modelYear;

        getVehicleAccessories(modelName, modelYear)
            .then((payload) => {
                let terms = [];
                let termsSet = new Set();
                    payload?.exterior?.map((accessory) => {
                        termsSet.add(accessory?.title)
                    });
                    payload?.interior?.map((accessory) => {
                        termsSet.add(accessory?.title)
                    });
                    payload?.performance?.map((accessory) => {
                        termsSet.add(accessory?.title)
                    });
                

                termsSet?.forEach((term) => terms.push(term));
                getVPTooltips(terms, setTooltips);

                setInteriorAccessories(payload?.interior);
                setExteriorAccessories(payload?.exterior);
                setPerformanceAccessories(payload?.performance);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });

        
    }, [props?.modelName, props?.modelYear]);
    useEffect(() => {
        collapseAccordionRef.current = props?.title?.toLowerCase();

        // Update accordionData based on props.title
        switch (props?.title) {
            case "Interior":
                setApiData(interiorAccessories);
                break;
            case "Exterior":
                setApiData(exteriorAccessories);
                break;
            case "Performance":
                setApiData(performanceAccessories);
                break;
            default:
                setApiData(interiorAccessories);
        }
    }, [props?.title, interiorAccessories, exteriorAccessories, performanceAccessories]);

    const buildSimpleAccordion = (data) => {
        // Add unique keys to each item in the accordionData array
        const dataWithKeys = data?.map((item, index) => ({
            ...item,
            key: item.title + index,
        }));

        // Check if dataWithKeys is empty
        const isEmptyData = !dataWithKeys?.length;
        let accordionData = {};
        if (!isEmptyData) {
            accordionData =
                dataWithKeys.map((item, index) => {
                    let indexOfUl = item?.description.indexOf('<ul>');
                    let paragraphDescription = indexOfUl >= 0 ? item?.description.slice(0, indexOfUl).trim() : item?.description;
                    let listDescription = indexOfUl >= 0 ? item?.description.slice(indexOfUl).trim() : "";
                    listDescription = listDescription?.replaceAll('<p><br></p>', '');
                    let disclaimer = item?.disclaimer == "-" ? "" : item.disclaimer;
                    disclaimer = disclaimer?.replaceAll('<p><br></p>', '');
                    return ({
                        title: tooltips[item.title] ? applyMarkdown(tooltips[item.title], {}, null, true, props?.applyTooltip, props?.glossaryData)
                        : applyMarkdown(item.title, null, null, true, props?.applyTooltip, props?.glossaryData),
                        body: (<AccessoriesAccordionContent
                            item={item}
                            paragraphDescription={paragraphDescription}
                            listDescription={listDescription}
                            disclaimer={disclaimer}
                        />),
                        key: item?.key
                    })
                });
        }

        return (
            <div className="accessories-v2-container">
                {isLoading ?
                    <>
                        <div className="accessories-msg-skeleton">
                            <Skeleton count={1.5} height={30} />
                        </div>
                        <div className="accessories-accordion-skeleton">
                            <Skeleton count={5} height={30} />
                        </div>
                    </>
                    :
                    (
                        isEmptyData ?
                            (
                                <div className="vehicle-page-no-accessories-section">
                                    Accessories information will be published as information becomes available.
                                </div>
                            ) :
                            (
                                <>
                                    <div className="accessories-important-message">
                                        <span className="important-message-body">
                                            <span className="important-message-bold">IMPORTANT:</span> Always confirm accessory part compatibility before reviewing with the customer, especially when selecting new accessories to add to a vehicle with certain accessories previously installed.
                                        </span>
                                    </div>
                                    <Accordion 
                                        expandCollapseAll
                                        rows={accordionData}
                                        ref={collapseAccordionRef}
                                        className="vehicle-page-accordion accessories-accordion"
                                        termAnalyticsClick={(term, isExpand) => {
                                            accordionClick({
                                                page: `Accessories:${props?.title}`,
                                                term: term,
                                                isExpand: isExpand,
                                                modelName: props.modelName,
                                                modelYear: props.modelYear,
                                                isNested: false,
                                            });
                                        }}
                                    /> 
                                </>
                            )
                    )
                }
            </div>
        );

    };


    return (
        <div>
            {buildSimpleAccordion(apiData)}
        </div>
    );
};

export default Accessories;
