import getNavigation from "../../services/navigationService";
import { getDynamoName, getFormattedName } from "../utils/vehicleName";
import { getGlossaryTooltips } from "../../services/glossaryService";
import { TooltipV2 } from "../../components/molecules/tooltipV2.js";
import { glossaryOverlayOnLoad, glossaryOverlayClose, glossaryOverlayCtaClick } from "../glossary/glossary.analytics.js";


export const getYearsFromRange = (yearRange) => {
  /*
    This function takes a list of year range objects like:
      [
        { startYear, endYear },
        { startYear }
        { year },
      ]
    and transforms it into a sorted list of years like:
      [ startYear ... endYear, year, startYear ... current calendar year + 1 ]
    ex:
      [
        { startYear: 2012, endYear: 2014 },
        { startYear: 2021 }
        { year: 2017 },
      ]
    returns:
      [ 2012, 2013, 2014, 2017, 2021, 2022, 2023, 2024] (2023 being current calendar year)
  
  */
  const rangeYears = yearRange
    .map((r) => ( 
      r.year ||
      Array.from(
        { length: (r.endYear || new Date().getFullYear() + 1) + 1 - r.startYear, }, 
        (v, i) => r.startYear + i
      )))
    .flat();
  rangeYears.sort().reverse();
  return rangeYears
}

export const yearInRange = (year, start, end) => {
  return (parseInt(year, 10) >= start && parseInt(year, 10) <= (end || new Date().getFullYear() + 1))
}

export const updateSidebarNavData = (navData, device, externalLinks, nested) => {
  return navData?.map(r => {
    switch (r.title.toLowerCase()) {
      case "ebrochure":
        return {
          ...r,
          title: device != "mobile" ? "eBROCHURE" : r.title,
          externalLink: externalLinks?.["eb"] || r.externalLink || ""
        }
      case "delivery checklist":
        return {
          ...r,
          title: device != "mobile" ? r.title.toUpperCase() : r.title,
          externalLink: externalLinks?.["dc"] || r.externalLink || ""
        }
      case "source book":
        return {
          ...r,
          title: device != "mobile" ? r.title.toUpperCase() : r.title,
          externalLink: externalLinks?.["sb"] || r.externalLink || ""
        }
      case "towing & payload calculator":
        return externalLinks?.["tp"] ? {
          ...r,
          title: device != "mobile" ? r.title.toUpperCase() : r.title,
          internalLink: externalLinks["tp"] || r.internalLink || ""
        } : undefined;
      default:
        return {
          ...r,
          key: r.key || r.urlPath || r.title.replace(' ', '-').toLowerCase(),
          title: (device != "mobile" && !nested) ? r.title.toUpperCase() : r.title,
          nestedItems: r.nestedItems ? updateSidebarNavData(r.nestedItems, device, null, true) : null
        }
    }
}).filter(item => item) // remove empty items from array
}

export const getBookmarkSubcategory = (currentSubcategory) => {
  if (currentSubcategory === "Comparisons") {
    if (window.location.pathname.includes("thirdPartyComparison")) {
      return `${currentSubcategory} - 3rd Party Spec`;
    } else if (
      window.location.pathname.includes("edge") ||
      window.location.pathname.includes("headToHead")
    ) {
      return `${currentSubcategory} - Head-to-Head`;
    } else if (window.location.pathname.includes("overview")) {
      return `${currentSubcategory} - Overview`;
    }
  } else {
    return currentSubcategory;
  }
}

export const buildVpUrl = (model, year, page, subpage) => {
  return `/product/${model}/${year}${page ? '/'+page : ''}${subpage ? '/'+subpage : ''}`
}

export const getVehicleCategories = async () => {
  const mainMenuNavData = await getNavigation('mainMenu');
  let vehicleCategories = mainMenuNavData.dropDownItems
      .find(i => i.isVehicleDropdown).menuItems
      .filter(i => i.title != 'Beyond Zero' && i.title != "Non-Production")
      .map(i => (
          {
              category: i.title.toLowerCase().includes("suv") ? "SUVs" : i.title,
              models: i.menuItems.map(j => j.model)
          }
      ))        
  vehicleCategories.unshift({category: "All", models: []});
  return vehicleCategories.map((i, index) => ({filter: {label: i.category, id: index.toString()}, models: i.models}));
};

export const getVehicleCategory = async (modelName) => {
  let vehicleType = 'car';
  const formattedModelName = getFormattedName(modelName);
  const categoryData = await getVehicleCategories();
      
  // The following filters intentionally do not look for an exact match in case category names change in the future
  const carList = categoryData.filter(item => item?.filter?.label?.toLowerCase().includes('car') || item?.filter?.label?.toLowerCase().includes('sedan'))[0]?.models;
  const suvList = categoryData.filter(item => item?.filter?.label?.toLowerCase().includes('suv'))[0]?.models;
  const truckList = categoryData.filter(item => item?.filter?.label?.toLowerCase().includes('truck'))[0]?.models;

  if (carList.includes(formattedModelName)) {
      vehicleType = 'car';
  } else if (suvList.includes(formattedModelName)) {
      vehicleType = 'suv';
  } else if (truckList.includes(formattedModelName)) {
      vehicleType = 'truck';
  }
  return vehicleType;
};

export const processSpecsNavItems = (menuItemsData, featuresAndSpecsData) => {
  const indexOfSpecs = menuItemsData?.findIndex((item) => item.key === "specifications");
  if (indexOfSpecs >= 0) {
    const newSpecsData = {
      ...menuItemsData[indexOfSpecs],
      nestedItems: featuresAndSpecsData?.Specs.map((spec) => ({
        key: convertNameToUrl(spec.name),
        urlPath: convertNameToUrl(spec.name),
        title: spec.name,
        parentTitle: "SPECIFICATIONS",
      })),
    };
    menuItemsData.splice(indexOfSpecs, 1, newSpecsData);
  }
  return menuItemsData;
};

// This function will likely be used for routing on the specs tabs but this is still a WIP
export const convertNameToUrl = (name) => {
  // Transforms to lowercase
  // Replaces all non alphanumeric characters with '-'
  // Replaces any instances of multiple '-' in a row with one '-' (i.e. '---' => '-')
  // Adds 'specifications?type=' to the start
  return `specifications?type=${convertNameToSlug(name)}`;
};

export const convertNameToSlug = (name) => {
  return name
    .toLowerCase()
    .replace(/\W/g, "-")
    .replace(/-+/g, "-")
}

export const loadingStatus = {
  NOT_STARTED: "NOT_STARTED",
  LOADING: "LOADING",
  DONE: "DONE",
  FAILED: "FAILED",
};

export const getVPTooltips = async (terms, handleResults) => {
  const response = await getGlossaryTooltips(terms);
  let temp = {};
  response.forEach((item) => {
      temp[item.originalTerm] = item.finalText;
  })
  handleResults(temp);
};

export const toCamelCase = ((str) => {

    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
});

export const getTooltip = (tooltipData, setVisiblePopupTooltip, history, clickAnalytics, pageName) => (
  
  <TooltipV2
      closeTooltip={()=>setVisiblePopupTooltip(null)}
      title={tooltipData ? tooltipData.term : null}
      subtitle={ tooltipData ? tooltipData.category : null}
      description={tooltipData ? tooltipData.description.slice(0,200)+"..." : null}
      moreLinkFunction={tooltipData ? ()=>{
        setVisiblePopupTooltip(null)
        history.push('/glossary/all?term=' + tooltipData.term)
      } : null}
      analyticsOpenTooltip={()=>glossaryOverlayOnLoad(clickAnalytics, tooltipData.term, pageName)}
      analyticsCloseTooltip={()=>glossaryOverlayClose(clickAnalytics, tooltipData.term, pageName)}
      analyticsMoreClick={()=>glossaryOverlayCtaClick(clickAnalytics, tooltipData.term, 'MORE', pageName)}
  />
  
);

export const getHasMultiplePowerTrainGroups = (trims) => {
  const powertrainCategories = trims && new Set(trims?.map(obj => obj?.powertrainCategory));
  return trims && powertrainCategories.size && powertrainCategories.size !== 1 ? true : false;
}