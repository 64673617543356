import PropTypes from 'prop-types';
import React from 'react';
import AppSettings from '../../../appsettings';
import '../../../styles/css/contactUs/contactUsForm/contactUsForm.css';
import applyMarkdown from '../../utils/applyMarkdown';
import ContactUsFormValues from './contactUsFormValues';

// Disabling form and rewriting description temporarily to redirect users to Help Desk
// Revert when there is a system to reply to Contact Form Emails 

const ContactUsForm = (props) => {
    return (
        <React.Fragment>
            <div className='contactUsFormDiv' style={{ backgroundImage: `url("${AppSettings.AWSImgRepo.contactUs + props.image}")` }} >
                <div className='contactUsForm'>
                    <div className='contactUsFormHeader'>{props.header}</div>
                    <div className='contactUsFormDescription'>
                        <h3>Pardon Our Progress.</h3>
                        <p>Have questions about the Toyota Engage website or mobile app?</p>
                        <p>Or just Toyota in general?</p>
                        <strong>While we improve our Contact Us page, please reach out to the <a href='https://tmna.service-now.com/1ts?id=1tslanding' target='_blank'>help desk</a></strong>
                    </div>
                    {/* <ContactUsFormValues
                        analytics={props.analytics}
                    /> */}
                </div>
            </div>
        </React.Fragment>
    );
};

ContactUsForm.propTypes = {
    analytics: PropTypes.object,
    header: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
};

export default ContactUsForm;