import React, {useEffect, useState} from 'react';
import applyMarkdown from '../../utils/applyMarkdown';
import RoundedButton from '../../../components/molecules/roundedButton';
import AppSettings from '../../../appsettings';
import { readAnnouncement } from '../../../services/notificationService';
import "../../../styles/css/profilePage/profile-page-announcements.css";
import { useHistory } from 'react-router';
import { learnMoreCardClick, notificationCardClick } from './notificationsPageAnalytics';

const AnnouncementCardV2 = (props) => {
    const showRedirectButton = !!props?.announcementData?.redirectPath;
    const [isRead, setIsRead] = useState(props?.announcementData?.isRead);
    const history = useHistory();
    const category = props?.announcementData?.category && props?.announcementData?.category.replace(" ", "_");

    useEffect(() => {
        setIsRead(props?.announcementData?.isRead);
    }, [props?.announcementData]);

    const clickAnnouncement = () => {
        notificationCardClick(props?.analyticsData?.clickAnalytics, props?.announcementData?.title);
        setIsRead(true);
        markAnnouncementAsRead(props?.announcementData?.id);
    };

    const markAnnouncementAsRead = async (id) => {
        //update state counts
        props.setNotificationCount({ ...props.notificationCount, unreadAnnouncements: props.notificationCount.unreadAnnouncements - 1 });
        await readAnnouncement(id);
    };

    function getFormattedDate() {
        const date = props?.announcementData?.promotedDate ? props?.announcementData?.promotedDate : props?.announcementData?.lastModifiedDate
        const formattedDate = date && new Date(date)
        const today = new Date()
        const timeSince = Math.round((today.getTime() - formattedDate.getTime()))

        // Calculate
        const minute = 1000 * 60
        const hour = minute * 60
        const day = hour * 24

        // Divide
        let minutes = Math.round(timeSince / minute)
        let hours = Math.round(timeSince / hour)
        let days = Math.round(timeSince / day)

        if (minutes < 60) {
            return( `${minutes} MIN AGO`)
        }
        if (hours < 23) {
            return( `${hours} HR AGO`)
        }
        if (days < 365) {
            if (days < 2) {
                return( `${days} DAY AGO`)
            }
            return( `${days} DAYS AGO`)
        } else {
            return (formattedDate ? formattedDate.toLocaleDateString() : "")
        }
    }

    const handleClickNavigation = (event) => {
        learnMoreCardClick(props?.analyticsData?.clickAnalytics, props?.announcementData?.title);
        event.target.blur();
        event.stopPropagation();
        history.push(props?.announcementData?.redirectPath);
        window.scrollTo({ top: 0 });
    }

    return (
        <>
            <div className={`announcementCard-V2 ${isRead ? 'read' : 'unread'}`} onClick={() => !isRead && clickAnnouncement()}>
                <div className='layout'>
                    <div className='icon'>
                        <img src={`${AppSettings.AWSImgRepo.announcements}${isRead ? 'Grey_' : 'Red_'}${category}.svg`} />
                    </div>
                    <div className='title'>
                        {applyMarkdown(props?.announcementData?.title)}
                    </div>
                    <div className='text-content'>
                        <div className='description'>
                            {applyMarkdown(props?.announcementData?.message)}
                        </div>
                        <div className='announcement-footer-V2'>
                            <div className='time'>
                                {getFormattedDate()}
                            </div>
                            {showRedirectButton && (
                                <RoundedButton
                                    title={"Learn More"}
                                    active={false}
                                    onClick={handleClickNavigation}
                                    className='redirect-button'
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            </>
    );
}

export default AnnouncementCardV2;