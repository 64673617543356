import { useRef } from "react";
import TitleText from "../../../components/atoms/TitleText";
import "../../../styles/css/model-overview-v2/key-selling-points.css";
import applyMarkdown from "../../utils/applyMarkdown";

const KeySellingPoints = (props) => {
  

  const getBackgroundImage = (url) => {
    return `${encodeURI(props.imagePath + url.imageLink)}`;
  };

  return (
    <div className="key-selling-points-section-wrapper">
      <div className="key-selling-points-section">
        <TitleText className="key-selling-points-title">
          {applyMarkdown(
            props.keySellingPoints?.sectionTitle,
            props.disclaimerRef.current
          )}
        </TitleText>
        <div className="key-selling-points-list">
          {props.keySellingPoints?.sellingPoints?.map((ksp, index) => (
            <div key={index} className="key-selling-points-card">
                <img
                  className="key-selling-points-image"
                  src={getBackgroundImage(ksp)}
                />
              <div className="key-selling-points-card-title">
                {applyMarkdown(ksp.text, props.disclaimerRef.current)}
              </div>
            </div>
          ))}
        </div>
        
      </div>
    </div>
  );
};

export default KeySellingPoints;
