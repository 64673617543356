import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AppSettings from "../../appsettings";
import { NotificationContext } from "../../components/contexts/notificationContext";
import  AnnouncementsPageV2  from "./notifications/announcementsPageV2";
import { getAnnouncements } from "../../services/notificationService";
import { WindowSize } from "../../components/contexts/windowSizeContext";
import { getDealership } from "../../services/profileService";
import '../../styles/scss/profilePage/profile-page-base.scss';
import SideBarMenu from "../sidebarMenu/sidebarMenu";
import { getJobTitle } from "../utils/getJobTitle";
import ProfilePageHeader from "./sharedComponents/profilePageHeader";
import ProfilePageMobileNav from "./sharedComponents/profilePageMobileNav";
import RecentActivities from "./recentActivity/recentActivities";
import { getUserActivityRecords } from "../../services/userActivityService";
import getAnalytics from "../../services/analyticsService";
import ProfileComponent from "./profile/profile"
import { getCommentsByUser } from "../../services/commentsService";
import { getArticlesByIds } from "../../services/articlesService";
import { profilePageSideBarMenuClick } from "./sharedComponents/sharedComponentsAnalytics";

const ProfilePage = () => {
    const { device } = useContext(WindowSize);
    const [currentProfilePage, setCurrentProfilePage] = useState(null);
    const [dealershipName, setDealershipName] = useState(null);
    const params = useParams();
    const history = useHistory();
    const [analyticsData, setAnalyticsData] = useState(null);
    const { notificationCount, setNotificationCount } = useContext(NotificationContext);
    const [announcements, setAnnouncements] = useState([]);
    const [announcementsLoading, setAnnouncementsLoading] = useState(true);
    const [browsingHistory, setBrowsingHistory] = useState([]);
    const [historyLoading, setHistoryLoading] = useState([]);
    const [topComments, setTopComments] = useState([]);
    const [commentsLoading, setCommentsLoading] = useState(true);
    const [articlesTitlesAndIds, setArticlesTitlesAndIds] = useState([]);
    const [menuItems, setMenuItems] = useState(
        [
            { key: 'profile', urlPath: 'profile', title: 'PROFILE', subcategory: 'PROFILE', id: 'PROFILE' },
            { key: 'notifications', urlPath: 'announcements', title: 'NOTIFICATIONS', subcategory: 'NOTIFICATIONS', id: 'NOTIFICATIONS', badge: notificationCount.unreadAnnouncements},
            { key: 'recent-activity', urlPath: 'activity', title: 'RECENT ACTIVITY', subcategory: 'RECENT ACTIVITY', id: 'RECENT ACTIVITY', badge: notificationCount.unreadReplies},
            { key: 'logout', urlPath: '/logout', title: 'LOG OUT', subcategory: 'LOG OUT', id: 'LOG OUT'}
        ]
    );

    useEffect(() => {
        fetchAnnouncementsData();
        fetchDealershipData();
        fetchRecentPagesData();
        fetchCommentsData();
        fetchAnalyticsData();
      }, []);

    useEffect(() => {
        setMenuItems(
            [
                { key: 'profile', urlPath: 'profile', title: 'PROFILE', subcategory: 'PROFILE', id: 'PROFILE' },
                { key: 'notifications', urlPath: 'announcements', title: 'NOTIFICATIONS', subcategory: 'NOTIFICATIONS', id: 'NOTIFICATIONS', badge: notificationCount.unreadAnnouncements},
                { key: 'recent-activity', urlPath: 'activity', title: 'RECENT ACTIVITY', subcategory: 'RECENT ACTIVITY', id: 'RECENT ACTIVITY', badge: notificationCount.unreadReplies },
                { key: 'logout', urlPath: '/logout', title: 'LOG OUT', subcategory: 'LOG OUT', id: 'LOG OUT'}
            ]
        );
    }, [notificationCount])

    // Add to as needed
    const userData = {
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        dealerName: localStorage.getItem("dealer") || dealershipName,
        region:
          localStorage.getItem("region") ||
          AppSettings.Regions[localStorage.getItem("region_code")] ||
          "No Region Found",
        regionID: localStorage.getItem("region_code"),
        zipCode: localStorage.getItem("zip_code") || null,
        spinId: localStorage.getItem("spin_id"),
        email: localStorage.getItem("email"),
        dealerCode: localStorage.getItem("dealer_code"),
        jobCode: localStorage.getItem("job_code"),
        jobTitle: getJobTitle(localStorage.getItem("job_code")),
    };

    useEffect(() => {
        const currentPageData = menuItems.find((item) => item.urlPath === params.page);

        if (currentPageData) {
            setCurrentProfilePage(currentPageData);
        } else {
            history.replace("/myaccount/profile");
        }
    }, [params.page]);

    const fetchAnnouncementsData = () => {
        getAnnouncements(localStorage.getItem('spin_id'))
            .then((data) => {
                setAnnouncements(data);
            }).catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setAnnouncementsLoading(false);
            })
    }

    const fetchRecentPagesData = async () => {
        try {
          const activityData = await getUserActivityRecords();

          // Assuming 'Items' property contains the browsing history
          setBrowsingHistory(activityData.Items || []);
          setHistoryLoading(false);
        } catch (error) {
          console.error('Error fetching user activity records:', error);
        }
    };

    const fetchCommentsData = async () => {
        getCommentsByUser()
            .then((data) => {
                const sortedComments = data?.sort((a, b) => {
                    return new Date(b.createdTimestamp) - new Date(a.createdTimestamp);
                });
                const topFourComments = sortedComments.slice(0, 4);
                setTopComments(topFourComments);
                getArticlesByIds(topFourComments.map(comment => comment.articleId))
                    .then((articlesTitlesAndIds) => {
                        setArticlesTitlesAndIds(articlesTitlesAndIds);
                    })
                    .catch((error) => {
                        console.log('error fetching comment data: ' + error)
                    })
                    .finally(() => {
                        setCommentsLoading(false);
                    });
            })
            .catch((error) => {
                console.log('error fetching comment data: ' + error);
                setCommentsLoading(false);
            });
    };

    const fetchDealershipData = () => {
        getDealership(localStorage.getItem("dealer_code"))
        .then((dealership) => {
            setDealershipName(dealership.dealerName);
        })
        .catch((error) => {
            console.log(
            `ERROR: failed to load dealership data. ${JSON.stringify(
                error.statusText
            )}`
            );
        });
    };

    const fetchAnalyticsData = () => {
        getAnalytics("profilePageRedesign")
        .then((data) => {
          setAnalyticsData(data);
        })
        .catch((error) => {
          console.log(`ERROR: failed to load Profile Page analytics data. ${error}`);
        });
    }

    const sideBarPageClickAnalytics = (item, parentTitle) => {
        profilePageSideBarMenuClick(analyticsData?.clickAnalytics, item?.title);
    }

    const getProfilePageComponent = () => {
        switch (currentProfilePage.urlPath) {
            case "announcements":
                return (
                    <AnnouncementsPageV2
                        announcements={announcements}
                        notificationCount={notificationCount}
                        setNotificationCount={setNotificationCount}
                        announcementsLoading={announcementsLoading}
                        analyticsData={analyticsData}
                    />
                );
            case "activity":
                return (
                    <RecentActivities
                        browsingHistory={browsingHistory}
                        topComments={topComments}
                        articlesTitlesAndIds={articlesTitlesAndIds}
                        commentsLoading={commentsLoading}
                        historyLoading={historyLoading}
                        analyticsData={analyticsData}
                    />
                );
            case "profile":
                return (
                    <ProfileComponent
                        userData={userData}
                        analyticsData={analyticsData}
                    />
                );
            default:
                return (
                    <AnnouncementsPageV2
                        announcements={announcements}
                        notificationCount={notificationCount}
                        setNotificationCount={setNotificationCount}
                    />
                );
        }
    };

  return (
    <div className="profile-page">
        <SideBarMenu
            menuItems={menuItems}
            device={device}
            setSelectedPage={setCurrentProfilePage}
            selectedPage={currentProfilePage}
            sideBarPageClickAnalytics={sideBarPageClickAnalytics}
        />
        <div className="profile-page-content">
            {device === "mobile" &&
                <ProfilePageMobileNav
                    tabs={menuItems}
                    currentTab={currentProfilePage}
                    analyticsData={analyticsData}
                />
            }
            <ProfilePageHeader
                userData={userData}
                showEditPictureButton={currentProfilePage?.urlPath === 'profile'}
                analyticsData={analyticsData}
            />
            {currentProfilePage && getProfilePageComponent()}
        </div>
    </div>
  );
};

export default ProfilePage;
