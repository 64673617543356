import { useMemo, useState, useEffect } from 'react';
import "../../../../styles/scss/beyondZero/quickLinks.scss";
import { Accordion } from "../../../../components/molecules/Accordion.js";
import AppSettings from "../../../../appsettings";
import RoundedButton from '../../../../components/molecules/roundedButton';
import applyMarkdown from '../../../utils/applyMarkdown';
import { handleLinkClick } from './utils';

const QuickLinks = ({ data, analyticsActions }) => {
    const [selectedItemKey, setSelectedItemKey] = useState(null);

    const handleButtonClick = (url, tabName) => {
        analyticsActions.onTabClick({
            label: 'Quick Links Button',
            content_title: tabName
        })

        handleLinkClick(url);
    };

    const handleCollapseChange = (key) => {
        const accordionItem = accordionData.find(a => a.key === key);
        const accordionAction = selectedItemKey === key ? 'Collapse' : 'Expand';
        analyticsActions.onTabClick({
            label: `Quick Links ${accordionAction}`,
            content_title: accordionItem.data.linkTitle
        })

        setSelectedItemKey(key);
    }

    const accordionData = useMemo(() => {
        return data?.links?.map((item, index) => ({
            title: (
                <div className='beyond-zero-quick-link-item-header'>
                    {applyMarkdown(item.linkTitle)}
                    <RoundedButton 
                        title={item.buttonText}
                        height="40px"
                        onClick={() => handleButtonClick(item.buttonUrl, item.linkTitle)}
                    />
                </div>
            ),
            body: (
                <div>
                    <span>{item.linkDescription}</span>
                    <img src={`${AppSettings.AWSImgRepo.beyondZero}${item.mobileImage}`} className='mobile-cover' />
                </div>
            ),
            key: `${item.linkTitle}.${index}`,
            data: item,
            expand: true
        }));
    }, [data?.links]);

    useEffect(() => {
        if (data?.links?.length > 0 && selectedItemKey === null) {
            setSelectedItemKey(`${data.links[0].linkTitle}.0`);
        }
    }, [data?.links, selectedItemKey]);

    const selectedItem = useMemo(() => {
        return accordionData?.find(item => item.key === selectedItemKey)?.data;
    }, [selectedItemKey, accordionData]);

    return (
        <div className="beyond-zero-quick-links-wrapper">
            <div className="quick-links-accordion-container">
                <div className='quick-links-accordion'>
                    <h1>{data?.title}</h1>
                    <Accordion 
                        autoCollapseExceptFirst
                        rows={accordionData}
                        className="beyond-zero-quick-links-accordion"
                        expandIcon={<img className='icon' src={`${AppSettings.AWSImgRepo.resources}en/nestedAccordionArrow.png`} alt='' style={{ transform: "scaleY(-1)" }} />}
                        collapseIcon={<img className='icon' src={`${AppSettings.AWSImgRepo.resources}en/nestedAccordionArrow.png`} alt='' />}
                        expandCollapseCallback={handleCollapseChange}
                    />
                </div>
            </div>
            <div 
                className="quick-links-banner-container"
                style={{
                    "--desktop-banner": `url(${AppSettings.AWSImgRepo.beyondZero}${selectedItem?.desktopImage})`,
                    "--tablet-banner": `url(${AppSettings.AWSImgRepo.beyondZero}${selectedItem?.tabletImage})`,
                    "--mobile-banner": `url(${AppSettings.AWSImgRepo.beyondZero}${selectedItem?.mobileImage})`,
                }}
            >
            </div>
        </div>
    );
}

export default QuickLinks;