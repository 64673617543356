let env;
let contactEmail;
let cloudFrontURL;
let hostUrl;
let redirect_uri;
let forgeRockWellKnown;
let logout_uri;
let authActivityTimeout;
let apiPath;
let apiPort;
let environment;
let tcuvUrl;
let triviaUrl;
let featureFlags;
let engageLiveGuid;
let engageLiveArchiveGuid;
let engageLiveState;
let toyotaConnectedServicesUrl;
const hostname = window && window.location && window.location.hostname;

// The following block of consts were made due to Sonarqube quiality gate issues.
// I advise not deleting them unless Sonarqube policies are changed or else
// it could lead to build failures.
const nonprodTcuvUrl = "https://tcuvstaging.hugheshunter.com/?option=saml_user_login&idp=Tess";
const nonprodTriviaUrl = "https://staging.engagetrivia.com";
const nonprodToyotaConnectedUrl = "https://oneportaltoyotadealer.stg.telematicsct.com/us";
const engageDevUrl = "https://dev.nonprod.engage.toyota.com";
const pueroRico = "Puerto Rico";
const regionalManager = "Regional Manager";
const dealerManager = "Dealer Manager";
const salesAssociate = "Sales Associate"

switch (hostname) {
  case "engage.toyota.com":
    env = "prod";
    break;
  case "staging.engage.toyota.com":
    env = "staging";
    break;
  case "test.nonprod.engage.toyota.com":
    env = "test";
    break;
  case "dev.nonprod.engage.toyota.com":
    env = "dev";
    break;
  default:
    env = "local";
    break;
}

switch (env) {
  case "prod":
    hostUrl = "https://engage.toyota.com";
    redirect_uri = "https://engage.toyota.com/auth/callback";
    forgeRockWellKnown = "https://ep.fram.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/.well-known/openid-configuration";
    logout_uri = "https://engage.toyota.com/logout/callback";
    authActivityTimeout = 50 * 60 * 1000; // 50 mins
    apiPath = "/api";
    apiPort = "443";
    environment = "prod";
    cloudFrontURL = "https://engage.toyota.com";
    contactEmail = "contact.us.engage@toyota.com";
    tcuvUrl = "https://hub.toyota.com/?option=saml_user_login&idp=Tess";
    triviaUrl = "https://engagetrivia.com";
    engageLiveGuid = "JIUc4vdWH0t";
    engageLiveArchiveGuid = "LR8wtRgTKF9";
    engageLiveState = "during";
    toyotaConnectedServicesUrl =
      "https://oneportaltoyotadealer.telematicsct.com/us";
    featureFlags = {
      showAnnouncements: true,
      showAppPage: true,
      showCertificationsAnnouncementsSystemStatus: true,
      showEngageLiveState: false,
      showEngageNews: true,
      showGlossary: true,
      showAmazonIncentives: true,
      showPowerBI: false,
      showRegionalContacts: false,
      useQumuForEngageLive: false,
      showSmartPathProgramResources: false,
      showRecallsAndSafetyCampaign: false,
      vinLookup: true,
      showSearchResultsGlossaryTab: false,
      showSearchResultsPagesTab: false,
      showTowingPayloadFAQsButton: true,
      showTowingPayloadDefinitionsButton: true,
      showTowingPayloadCommonWeightsButton: true,
      showTowingPayloadMoreLink: false,
      showTowingPayloadFAQsSearchBar: true,
      showTowingPayloadDefinitionsSearchBar: true,
      showTowingPayloadCommonWeightsSearchBar: true,
      showFeatureLookup: true,
    };
    break;
  case "staging":
    hostUrl = "https://staging.engage.toyota.com";
    redirect_uri = "https://staging.engage.toyota.com/auth/callback";
    forgeRockWellKnown = "https://ep.fram.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/.well-known/openid-configuration";
    logout_uri = "https://staging.engage.toyota.com/logout/callback";
    authActivityTimeout = 50 * 60 * 1000; // 50 mins
    apiPath = "/api";
    apiPort = "443";
    environment = "staging";
    cloudFrontURL = "https://staging.engage.toyota.com";
    contactEmail = "contact.us.engage@toyota.com";
    tcuvUrl = nonprodTcuvUrl;
    triviaUrl = nonprodTriviaUrl;
    engageLiveGuid = "NtUxlFT55nb";
    engageLiveArchiveGuid = "Tm21NA6f8lh";
    engageLiveState = "during";
    toyotaConnectedServicesUrl = nonprodToyotaConnectedUrl;
    featureFlags = {
      showAnnouncements: true,
      showAppPage: true,
      showCertificationsAnnouncementsSystemStatus: true,
      showEngageLiveState: true,
      showEngageNews: true,
      showGlossary: true,
      showAmazonIncentives: true,
      showPowerBI: true,
      showRegionalContacts: true,
      useQumuForEngageLive: false,
      showSmartPathProgramResources: true,
      showRecallsAndSafetyCampaign: true,
      vinLookup: true,
      showSearchResultsGlossaryTab: false,
      showSearchResultsPagesTab: false,
      showTowingPayloadFAQsButton: true,
      showTowingPayloadDefinitionsButton: true,
      showTowingPayloadCommonWeightsButton: true,
      showTowingPayloadMoreLink: false,
      showTowingPayloadFAQsSearchBar: true,
      showTowingPayloadDefinitionsSearchBar: true,
      showTowingPayloadCommonWeightsSearchBar: true,
      showFeatureLookup: true,
    };
    break;
  case "test":
    hostUrl = "https://test.nonprod.engage.toyota.com";
    redirect_uri = "https://test.nonprod.engage.toyota.com/auth/callback";
    forgeRockWellKnown = "https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/.well-known/openid-configuration";
    logout_uri = 'https://test.nonprod.engage.toyota.com/logout/callback';
    authActivityTimeout = 8 * 60 * 1000; // 8 mins
    apiPath = "/api";
    apiPort = "443";
    environment = "test";
    cloudFrontURL = "https://test.nonprod.engage.toyota.com";
    contactEmail = "contact.us.engage@toyota.com";
    tcuvUrl = nonprodTcuvUrl;
    triviaUrl = nonprodTriviaUrl;
    engageLiveGuid = "NtUxlFT55nb";
    engageLiveArchiveGuid = "Tm21NA6f8lh";
    engageLiveState = "during";
    toyotaConnectedServicesUrl = nonprodToyotaConnectedUrl;
    featureFlags = {
      showAnnouncements: true,
      showAppPage: true,
      showCertificationsAnnouncementsSystemStatus: true,
      useQumuForEngageLive: true,
      showEngageLiveState: true,
      showEngageNews: true,
      showGlossary: true,
      showAmazonIncentives: true,
      showPowerBI: true,
      showNewHomepage: true,
      showRegionalContacts: true,
      showSmartPathProgramResources: true,
      showRecallsAndSafetyCampaign: true,
      vinLookup: true,
      showSearchResultsGlossaryTab: false,
      showSearchResultsPagesTab: false,
      showTowingPayloadFAQsButton: true,
      showTowingPayloadDefinitionsButton: true,
      showTowingPayloadCommonWeightsButton: true,
      showTowingPayloadMoreLink: false,
      showTowingPayloadFAQsSearchBar: true,
      showTowingPayloadDefinitionsSearchBar: true,
      showTowingPayloadCommonWeightsSearchBar: true,
      showFeatureLookup: true,
    };
    break;
  case "dev":
    hostUrl = engageDevUrl;
    redirect_uri = "https://dev.nonprod.engage.toyota.com/auth/callback";
    forgeRockWellKnown = "https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/.well-known/openid-configuration";
    logout_uri = "https://dev.nonprod.engage.toyota.com/logout/callback";
    authActivityTimeout = 8 * 60 * 1000; // 8 mins
    apiPath = "/api";
    apiPort = "443";
    environment = "dev";
    cloudFrontURL = engageDevUrl;
    contactEmail = "contact.us.engage@toyota.com";
    tcuvUrl = nonprodTcuvUrl;
    triviaUrl = nonprodTriviaUrl;
    engageLiveGuid = "NtUxlFT55nb";
    engageLiveArchiveGuid = "Tm21NA6f8lh";
    engageLiveState = "during";
    toyotaConnectedServicesUrl = nonprodToyotaConnectedUrl;
    featureFlags = {
      showAnnouncements: true,
      showAppPage: true,
      showCertificationsAnnouncementsSystemStatus: true,
      showEngageLiveState: true,
      showEngageNews: true,
      showGlossary: true,
      showAmazonIncentives: true,
      showPowerBI: true,
      showRegionalContacts: true,
      useQumuForEngageLive: true,
      showSmartPathProgramResources: false,
      showRecallsAndSafetyCampaign: true,
      vinLookup: true,
      showSearchResultsGlossaryTab: false,
      showSearchResultsPagesTab: false,
      showTowingPayloadFAQsButton: true,
      showTowingPayloadDefinitionsButton: true,
      showTowingPayloadCommonWeightsButton: true,
      showTowingPayloadMoreLink: false,
      showTowingPayloadFAQsSearchBar: true,
      showTowingPayloadDefinitionsSearchBar: true,
      showTowingPayloadCommonWeightsSearchBar: true,
      showFeatureLookup: true,
    };
    break;
  case "local":
    hostUrl = "http://localhost";
    redirect_uri = "http://localhost:3000/auth/callback";
    forgeRockWellKnown = "https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/.well-known/openid-configuration";
    logout_uri = "http://localhost:3000/logout/callback";
    authActivityTimeout = 8 * 60 * 1000; // 8 mins
    apiPath = "/api";
    apiPort = "8080";
    environment = "dev";
    cloudFrontURL = engageDevUrl;
    contactEmail = "contact.us.engage@toyota.com";
    tcuvUrl = nonprodTcuvUrl;
    triviaUrl = nonprodTriviaUrl;
    engageLiveGuid = "NtUxlFT55nb";
    engageLiveArchiveGuid = "Tm21NA6f8lh";
    engageLiveState = "during";
    toyotaConnectedServicesUrl = nonprodToyotaConnectedUrl;
    featureFlags = {
      showAnnouncements: true,
      showAppPage: true,
      showCertificationsAnnouncementsSystemStatus: true,
      showEngageLiveState: true,
      showEngageNews: true,
      showGlossary: true,
      showAmazonIncentives: true,
      showPowerBI: true,
      showRegionalContacts: true,
      useQumuForEngageLive: true,
      showSmartPathProgramResources: false,
      showRecallsAndSafetyCampaign: true,
      vinLookup: true,
      showSearchResultsGlossaryTab: false,
      showSearchResultsPagesTab: false,
      showTowingPayloadFAQsButton: true,
      showTowingPayloadDefinitionsButton: true,
      showTowingPayloadCommonWeightsButton: true,
      showTowingPayloadMoreLink: false,
      showTowingPayloadFAQsSearchBar: true,
      showTowingPayloadDefinitionsSearchBar: true,
      showTowingPayloadCommonWeightsSearchBar: true,
      showFeatureLookup: true,
    };
    break;
  default:
    throw new Error("Environment variable not set correctly");
}

export default {
  ContactEmail: contactEmail,
  hostUrl: hostUrl,
  redirect_uri: redirect_uri,
  forgeRockWellKnown: forgeRockWellKnown,
  oidcScope: "openid profile",
  oidcTokenType: "Bearer",
  logout_uri: logout_uri,
  authActivityTimeout,
  apiPath: apiPath,
  apiPort: apiPort,
  environment: environment,
  cloudFrontURL: cloudFrontURL,
  TcuvUrl: tcuvUrl,
  TriviaUrl: triviaUrl,
  BasePath: "",
  FeatureFlags: featureFlags,
  AWSImgRepo: {
    amm: `${cloudFrontURL}/static/images/amm/`,
    announcements: `${cloudFrontURL}/static/images/announcements/`,
    appPage: `${cloudFrontURL}/static/images/appLanding/en/`,
    appLinks: `${cloudFrontURL}/static/images/appLinks/`,
    articleImages: `${cloudFrontURL}/static/articles`,
    beyondZero: `${cloudFrontURL}/static/images/beyondZero/`,
    certification: `${cloudFrontURL}/static/images/certification/en/`,
    compAdvOverview: `${cloudFrontURL}/static/images/competitive_advantages_overview/`,
    contactUs: `${cloudFrontURL}/static/images/contactUs/`,
    cloudFrontURL,
    edge: `${cloudFrontURL}/static/images/edge/`,
    engage_live: `${cloudFrontURL}/static/images/engage_live/`,
    nitroOverview: `${cloudFrontURL}/static/images/nitroOverview/`,
    engageXP: `${cloudFrontURL}/static/images/engageXP/`,
    entune: `${cloudFrontURL}/static/images/entune/`,
    financeLeasing: `${cloudFrontURL}/static/images/financing_leasing/`,
    glossary: `${cloudFrontURL}/static/images/glossary/`,
    homepage: `${cloudFrontURL}/static/images/homepage/`,
    homepage_v2: `${cloudFrontURL}/static/images/homepage_v2/`,
    homepage_v3: `${cloudFrontURL}/static/images/homepage_v3/`,
    hybrid: `${cloudFrontURL}/static/images/hybrid/`,
    jellybeans: `${cloudFrontURL}/static/images/jellybeans/`,
    logo: `${cloudFrontURL}/static/images/static/`,
    myAccount: `${cloudFrontURL}/static/images/myAccount/`,
    nsa: `${cloudFrontURL}/static/images/new_sales_associate/`,
    recallAndSafety: `${cloudFrontURL}/static/images/recalls/`,
    resources: `${cloudFrontURL}/static/images/resources/`,
    safety: `${cloudFrontURL}/static/images/safety/`,
    safetyConnect: `${cloudFrontURL}/static/images/safety_connect/`,
    safetyLanding: `${cloudFrontURL}/static/images/safetyLanding/`,
    serviceConnect: `${cloudFrontURL}/static/images/serviceConnect/`,
    smartPath: `${cloudFrontURL}/static/images/smartpath/en/`,
    sourcebooks: `${cloudFrontURL}/static/images/sourcebooks/`,
    sss: `${cloudFrontURL}/static/images/starSafetySystem/en/`,
    static: `${cloudFrontURL}/static`,
    staticImages: `${cloudFrontURL}/static/images/static/`,
    towingPayload: `${cloudFrontURL}/static/images/towing_payload_101/`,
    tss: `${cloudFrontURL}/static/images/toyota_safety_sense/`,
    tcuv: `${cloudFrontURL}/static/images/tcuv/`,
    vehicle: `${cloudFrontURL}/static/images/vehicle/`,
    vehicleCategoryCards: `${cloudFrontURL}/static/images/vehicle-category-cards/`,
    featuredVehicles: `${cloudFrontURL}/static/images/featured-vehicles/`,
  },
  AWSFileRepo: {
    brochures: `${cloudFrontURL}/static/files/ebrochures/`,
    certification: `${cloudFrontURL}/static/files/certification/`,
    certificationImages: `${cloudFrontURL}/static/images/certification/`,
    deliveryChecklist: `${cloudFrontURL}/static/docs/Delivery/`,
    pdfs: `${cloudFrontURL}/static`,
    sourceBooks: `${cloudFrontURL}/static/files/sourcebooks/`,
  },
  ScreenWidthBreakpoints: {
    small: 640,
    medium: 1024,
  },
  PaintHexCodes: {
    "040": "#FFFFFF",
    "058": "#efeeed",
    "062": "#f0eeeb",
    "068": "#f4f3f2",
    "070": "#F3F2F2",
    "082": "#f5f7ed",
    "084": "#f3f3f2",
    "089": "#e3e9e9",
    K1X: "#e0ddd4",
    202: "#000000",
    209: "#1d1e1e",
    218: "#00031E",
    219: "#1d1c1c",
    221: "#0d2c37",
    "41W": "#080808",
    D4S: "#191918",
    "61K": "#40403F",
    "42A": "#3a3a3a",
    "1D4": "#dcdce0",
    "1D6": "#c6c8cc",
    "1D7": "#d5d5d5",
    "1E0": "#3e3d44",
    "1E7": "#e5e5e9",
    "1F7": "#808080",
    "1F8": "#99a4aa",
    "1F9": "#3d424a",
    "1G2": "#767471",
    "1G3": "#494848",
    "1G6": "#a0a9a8",
    "1H1": "#63615e",
    "1H2": "#062438",
    "1H5": "#737B82",
    "1J6": "#d1d4d7",
    "1J9": "#828387",
    "1K0": "#a4a09f",
    A4D: "#cfcfcf",
    G1U: "#808080",
    "38P": "#7a797a",
    "3L5": "#d3202a",
    "3P0": "#b1160c",
    "3P2": "#420515",
    "3Q3": "#8d1a2c",
    "3Q7": "#5f0426",
    "3Q8": "#842020",
    "3R8": "#5a1414",
    "3R0": "#4c1b31",
    "3R3": "#a21e22",
    "3S7": "#f92d68",
    "3T0": "#470012",
    "3T3": "#740000",
    "3T7": "#940d0e",
    M7Y: "#b2040b",
    C7P: "#660000",
    "41G": "#9f1204",
    "45P": "#a8a8a9",
    "4Q2": "#b7aa86",
    "4R3": "#b29d76",
    "4R8": "#b9370a",
    "4S6": "#4f0b04",
    "4T3": "#776352",
    "4T6": "#d58035",
    "4T8": "#c3b79d",
    "4U2": "#7f7056",
    "4U5": "#382c22",
    "4U3": "#673f18",
    "4V6": "#af9a7c",
    "4V7": "#e86542",
    "4V8": "#605c55",
    "4W2": "#d15329",
    "4W4": "#4f3b35",
    "4X0": "#d63400",
    "4X1": "#beb8b8",
    "4X3": "#f87907",
    "4X4": "#412C1B",
    "4X7": "#372b23",
    H8R: "#992d0b",
    588: "#acc036",
    "5A3": "#f8a805",
    "5B2": "#C7B7A3",
    "4V0": "#77675c",
    NBB: "#c13d27",
    "6T3": "#122d2c",
    GT7: "#767c67",
    "6T8": "#224234",
    "6U0": "#adb59d",
    "6V2": "#384021",
    "6V4": "#2c2e15",
    "6V7": "#2a3a2a",
    "6W2": "#a9a100",
    "6W4": "#5C625C",
    "6W7": "#20371f",
    "6W9": "#2b7a78",
    774: "#5fa8aa",
    776: "#576c67",
    777: "#698580",
    779: "#266d6b",
    781: "#94adaf",
    785: "#013341",
    787: "#d0e0ea",
    788: "#0a5d6b",
    791: "#458a96",
    "8N0": "#7491af",
    "8P1": "#056fb7",
    "8P8": "#032945",
    "8R3": "#406080",
    "8R5": "#7ca1ac",
    "8S4": "#78898d",
    "8S6": "#1b1a49",
    "8S7": "#788cb2",
    "8T0": "#15499c",
    "8T1": "#3d5767",
    "8T5": "#273476",
    "8T7": "#0359a6",
    "8U6": "#2b5e7f",
    "8U8": "#a1b2b9",
    "8V1": "#2d3544",
    "8V5": "#42556d",
    "8V7": "#70a3bb",
    "8W1": "#8eaece",
    "8W2": "#2a5180",
    "8W6": "#102740",
    "8W7": "#111d69",
    "8X2": "#354c9c",
    K3X: "#2b2373",
    "42M": "#001644",
    "44J": "#043589",
    "8X7": "#0a5e96",
    "8Y1": "#05678b",
    E8H: "#03193c",
    "9AG": "#969fb9",
    "9AH": "#2d141c",
    "4U0": "#BCAA97",
    792: "#468CA3",
    PBA: "#43434B",
    "1K4": "#ADB4B8",
    "4X9": "#72605A",
    "8W9": "#26A0DE",
    "6X1": "#857A6A",
    "3U4": "#A41F1C",
    "3U5": "#E20500",
    DAR: "#0F7AFF",
    "8T6": "#19529b",
    // TODO: Replace if business wants/add 2 tone functionality
    "2PS": "#e3e9e9",
    "2PT": "#828387",
    "2QQ": "#0359a6",
    "2NG": "#354c9c",
    "2NE": "#740000",
    "2NJ": "#2b7a78",
    "2PN": "#740000",
    "2PP": "#354c9c",
    "2MR": "#d1d4d7",
    "2MS": "#1d1c1c",
    "2MT": "#1b1a49",
    "2NL": "#f0eeeb",
    "2NM": "#8d1a2c",
    "2NV": "#05678b",
    "2NA": "#F3F2F2",
    "2RV": "#26A0DE",
    "2NF": "#740000",
    "2NK": "#A4A09F",
    "47A": "#BBBEBD",
    "2QU": "#949B96",
    "2QV": "#26A0DE",
    "2QW": "#00031E",
    "2QJ": "#F3F2F2",
    "2QY": "#C6C8CC",
    "2QZ": "#494848",
    "2RA": "#303B76",
    "6X3": "#949B96",
    "1K3": "#636B79",
    "2SC": "#E20500",
    D01: "#EBEAE5",
    D07: "#1D306F",
    D06: "#DDB329",
    D04: "#161617",
    D05: "#B61E0D",
    D02: "#DCDCDE",
    D03: "#B0B7BD",
    D08: "#CCD0D3",
    P8Y: "#79787E",
    DAN: "#285A38",
    "2UD": "#857A6A",
    "2UF": "#808080",
    "2UG": "#A41F1C",
    "2UH": "#26A0DE",
    "2QX": "#494848",
    "2NH": "#354c9c",
    "2TA": "#1d1e1e",
    "2TC": "#992d0b",
    "2TB": "#E20500",
    "1K5": "#8898AC",
    "8X8": "#1E2548",
  },
  Regions: {
    10: "TMS",
    11: "Los Angeles",
    110: "Los Angeles Corporate",
    12: "San Francisco",
    120: "San Francisco Corporate",
    13: "Portland",
    130: "Portland Corporate",
    15: "Denver",
    150: "Denver Corporate",
    16: "New York",
    160: "New York Corporate",
    17: "Boston",
    170: "Boston Corporate",
    21: "Chicago",
    210: "Chicago Corporate",
    22: "Cincinnati",
    220: "Cincinnati Corporate",
    23: "Kansas City",
    230: "Kansas City Corporate",
    30: "Lexus HQ",
    300: "Lexus HQ Corporate",
    50: "Southeast",
    500: "Southeast Corporate",
    60: "Gulf States",
    600: "Gulf States Corporate",
    80: "CATD",
    800: "CATD Corporate",
    81: "21SNovemRegion",
    82: pueroRico,
    820: "Puerto Rico Corporate",
    83: "Mexico",
    830: "Mexico Corporate",
  },
  TriviaRegions: {
    0: "TMS Corporate",
    1: "TMS",
    2: "Los Angeles",
    3: "San Francisco",
    4: "Portland",
    5: "Denver",
    6: "New York",
    7: "Boston",
    8: "Chicago",
    9: "Cincinnati",
    10: "Kansas City",
    11: "Southeast",
    12: "Gulf States",
    13: "CATD",
    14: pueroRico,
    15: pueroRico,
    16: pueroRico,
  },
  BATIncentivesRegions: {
    "Los Angeles": "socal",
    "Los Angeles Corporate": "socal",
    "San Francisco": "norcal",
    "San Francisco Corporate": "norcal",
    "Portland": "pacificnorthwest",
    "Portland Corporate": "pacificnorthwest",
    "Denver": "denver",
    "Denver Corporate": "denver",
    "New York": "greaterny",
    "New York Corporate": "greaterny",
    "Boston": "newengland",
    "Boston Corporate": "newengland",
    "Chicago": "midwest",
    "Chicago Corporate": "midwest",
    "Cincinnati": "mi-oh-ky-tn",
    "Cincinnati Corporate": "mi-oh-ky-tn",
    "Kansas City": "central",
    "Kansas City Corporate": "central",
    "Southeast": "southeasttoyota",
    "Southeast Corporate": "southeasttoyota",
    "CATD": "centralatlantic",
    "CATD Corporate": "centralatlantic",
    "Gulf States": "gst",
    "Gulf States Corporate": "gst"
  },
  JobCode: {
    0: "Team Member",
    "086": "TMS",
    "020": regionalManager,
    "061": regionalManager,
    "071": regionalManager,
    "072": regionalManager,
    "073": regionalManager,
    "074": regionalManager,
    "075": regionalManager,
    "076": regionalManager,
    "078": regionalManager,
    "079": regionalManager,
    "093": regionalManager,
    "094": regionalManager,
    "096": regionalManager,
    1: regionalManager,
    103: regionalManager,
    104: regionalManager,
    106: regionalManager,
    107: regionalManager,
    120: regionalManager,
    121: regionalManager,
    122: regionalManager,
    123: regionalManager,
    124: regionalManager,
    125: regionalManager,
    126: regionalManager,
    127: regionalManager,
    128: regionalManager,
    129: regionalManager,
    142: regionalManager,
    143: regionalManager,
    "001": dealerManager,
    "002": dealerManager,
    "003": dealerManager,
    "004": dealerManager,
    "005": dealerManager,
    "006": dealerManager,
    "007": dealerManager,
    "008": dealerManager,
    "009": dealerManager,
    "010": dealerManager,
    "017": dealerManager,
    "018": dealerManager,
    "025": dealerManager,
    "029": dealerManager,
    "032": dealerManager,
    "034": dealerManager,
    "064": dealerManager,
    115: dealerManager,
    131: dealerManager,
    132: dealerManager,
    133: dealerManager,
    139: dealerManager,
    141: dealerManager,
    144: dealerManager,
    158: dealerManager,
    173: dealerManager,
    110: dealerManager,
    "000": salesAssociate,
    "011": salesAssociate,
    "012": salesAssociate,
    "013": salesAssociate,
    "014": salesAssociate,
    "015": salesAssociate,
    "016": salesAssociate,
    "021": salesAssociate,
    "022": salesAssociate,
    "023": salesAssociate,
    "024": salesAssociate,
    "026": salesAssociate,
    "027": salesAssociate,
    "028": salesAssociate,
    "030": salesAssociate,
    "031": salesAssociate,
    "033": salesAssociate,
    "036": salesAssociate,
    "038": salesAssociate,
    "039": salesAssociate,
    "040": salesAssociate,
    "041": salesAssociate,
    "042": salesAssociate,
    "043": salesAssociate,
    "044": salesAssociate,
    "045": salesAssociate,
    "046": salesAssociate,
    "047": salesAssociate,
    "048": salesAssociate,
    "049": salesAssociate,
    "050": salesAssociate,
    "051": salesAssociate,
    "052": salesAssociate,
    "053": salesAssociate,
    "054": salesAssociate,
    "055": salesAssociate,
    "060": salesAssociate,
    "062": salesAssociate,
    "063": salesAssociate,
    "065": salesAssociate,
    "066": salesAssociate,
    "067": salesAssociate,
    "068": salesAssociate,
    "069": salesAssociate,
    "070": salesAssociate,
    "077": salesAssociate,
    "081": salesAssociate,
    "098": salesAssociate,
    "099": salesAssociate,
    101: salesAssociate,
    102: salesAssociate,
    105: salesAssociate,
    108: salesAssociate,
    111: salesAssociate,
    112: salesAssociate,
    113: salesAssociate,
    114: salesAssociate,
    116: salesAssociate,
    117: salesAssociate,
    118: salesAssociate,
    119: salesAssociate,
    134: salesAssociate,
    135: salesAssociate,
    136: salesAssociate,
    137: salesAssociate,
    138: salesAssociate,
    140: salesAssociate,
    145: salesAssociate,
    146: salesAssociate,
    147: salesAssociate,
    148: salesAssociate,
    149: salesAssociate,
    150: salesAssociate,
    151: salesAssociate,
    152: salesAssociate,
    153: salesAssociate,
    154: salesAssociate,
    155: salesAssociate,
    156: salesAssociate,
    157: salesAssociate,
    159: salesAssociate,
    160: salesAssociate,
    161: salesAssociate,
    170: salesAssociate,
    171: salesAssociate,
    172: salesAssociate,
    174: salesAssociate,
    321: salesAssociate,
    322: salesAssociate,
  },
  EngageLiveState: engageLiveState,
  EngageLiveGuid: engageLiveGuid,
  EngageLiveArchiveGuid: engageLiveArchiveGuid,
  ToyotaConnectedServicesUrl: toyotaConnectedServicesUrl,
};
