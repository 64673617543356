import { useEffect, useState, useRef, useContext } from "react";
import { getAllVehicleModels, getComparisonReport, getModelTrims } from "../../../services/comparisonService";
import "../../../styles/scss/resources-v2/third-party-comparison.scss";
import "../../../styles/scss/resources-v2/third-party-comparison-print-only.scss";
import "../../../styles/scss/resources-v2/disclaimers-footnotes-print-only.scss";
import "../../../styles/css/vehicleDetails/details-base.css";
import { ThirdPartyComparisonFilter } from "./components/thirdParyComparisonFilter";
import { isMobileView } from "../../utils/getScreenSize";
import Skeleton from "react-loading-skeleton";
import { NestedAccordion } from "../../../components/molecules/nestedAccordion";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";
import getAlternativeVehicleNames from "../../../services/alternativeVehicleNames.js"
import { getVehicleCategory } from "../../VPRouter/VPUtils";
import { accordionClick } from "../../vehicleDetails/vehicleDetails.analytics";
import { getVPTooltips } from "../../VPRouter/VPUtils";
import applyMarkdown from "../../utils/applyMarkdown.js";
import { VehiclePageIsPrint } from "../../../components/contexts/vehiclePagePrintContext.js";

export const ThirdPartyComparison = (props) => {
    const [makeList, setMakeList] = useState(null);
    const [initialModelId, setInitialModelId] = useState(null);
    const [initialTrimData, setInitialTrimData] = useState(null);
    const [initialYear, setInitialYear] = useState(null);
    const [formattedModelName, setFormattedeModelName] = useState(null);
    const [numComparisons, setNumComparisons] = useState(4);
    const [loadingTrims, setLoadingTrims] = useState([]);
    const [firstLoadComplete, setFirstLoadComplete] = useState(false);
    const disclaimerComponentRef = useRef();
    const [shadowImage, setShadowImage] = useState(null);
    const [vehicleInformation, setVehicleInformation] = useState(
        [
            {make: 'Toyota', modelName: '', modelId: '', modelOptions: [], trimName: '', trimId: '', trimOptions: [], year: '',imageUrl: ''},
            {make: '', modelName: '', modelId: '', modelOptions: [], trimName: '', trimId: '', trimOptions: [], year: '',imageUrl: ''},
            {make: '', modelName: '', modelId: '', modelOptions: [], trimName: '', trimId: '', trimOptions: [], year: '',imageUrl: ''},
            {make: '', modelName: '', modelId: '', modelOptions: [], trimName: '', trimId: '', trimOptions: [], year: '',imageUrl: ''},
        ]
    );
    const [vehicleOneTrim, setVehicleOneTrim] = useState("vehicleOneTrim");
    const [tooltips, setTooltips] = useState({});
    const [shouldCollapseAllChildren, setShouldCollapseAllChildren] = useState(false);
    const [shouldExpandAllChildren, setShouldExpandAllChildren] = useState(false);

    const [comparisonData, setComparisonData] = useState(null);
    const [jatoFailure, setJatoFailure] = useState(false);
    const { isPrint } = useContext(VehiclePageIsPrint);
    
    useEffect(() => {
        if(initialModelId) {
            getInitialTrims();
        }
    }, [initialModelId]);

    useEffect(() => {
        if(initialTrimData) {
            setInitialToyotaData();
            getInitialComparison(initialTrimData[0]?.trimId);
        }
    }, [initialTrimData]);

    useEffect(() => {

        getAllVehicleModels()
            .then((data) => {
                setMakeList(data?.makeNameList);
                getInitialModelId(data?.makeNameList);
            })
            .catch((error) => {
                console.log(error);
            });
        handleShadowImage(props?.model);
        
    }, []);

    useEffect(() => {
        if(window.innerWidth > 1300) {
            setNumComparisons(4);
        } else if (window.innerWidth > 800) {
            setNumComparisons(3);
        } else {
            setNumComparisons(2);
        }
    }, [window.innerWidth])

    useEffect(()=> {
        let terms = []
        const termsSet = new Set(comparisonData?.map((category) => (category?.specCategoryData?.map((subCategory) => subCategory.specName))).flat());
        termsSet?.forEach((term) => terms.push(term));

        getVPTooltips(terms, setTooltips);
    }, [comparisonData]);

    const getInitialModelId = (data) => {
        getAlternativeVehicleNames()
            .then((alternateNames) => {
                const jatoName = alternateNames?.Items?.find(vehicleObj => vehicleObj.vehicleModelName?.toLowerCase() === props?.model?.toLowerCase())
                    ?.jatoName || props?.model?.toLowerCase();

                const findModel = data?.filter(item => item.makeName?.toLowerCase() === 'toyota')[0]
                    ?.modelNameList
                    ?.filter(model => model?.modelName?.toLowerCase() === jatoName?.toLowerCase())[0];
                setFormattedeModelName(findModel?.modelName);
        
                const parsedAndSortedData = findModel?.modelYearList
                    ?.map(list => ({modelYear: list.modelYear, modelId: list.modelId.modelId}))
                    ?.sort((a, b) => b.modelYear - a.modelYear);
                
                let initialModelIdTemp = '';
                initialModelIdTemp = parsedAndSortedData?.filter(item => item.modelYear == props?.year)[0]?.modelId;
                setInitialYear(props?.year);
        
                // If no data for current year, set initial model ID to newest model with data
                if(!initialModelIdTemp) {
                    initialModelIdTemp = parsedAndSortedData?.[0]?.modelId;
                    setInitialYear(parsedAndSortedData?.[0]?.modelYear);
                }
        
                // No data for the given vehicle
                if(!initialModelIdTemp) {
                    setJatoFailure(true);
                }

                setInitialModelId(initialModelIdTemp);
            });
    };

    const getModelId = (makeName, modelName, year) => {
        const modelId = makeList?.filter(item => item.makeName?.toLowerCase() === makeName?.toLowerCase())[0]
            ?.modelNameList
            ?.filter(model => model.modelName.toLowerCase() === modelName.toLowerCase())[0]
            ?.modelYearList
            ?.filter(modelYear => modelYear.modelYear == year)[0]
            ?.modelId.modelId;
        return(modelId);
    };

    const getInitialComparison = (initialTrimId) => {
        getComparisonReport([initialTrimId])
            .then((data) => {
            if(!isMobileView(window.innerWidth)) {
                setFirstCompetitorData(data?.competitorVehicles[0]);
            }
            setFirstLoadComplete(true);
        })
        .catch((error) => {
            setJatoFailure(true);
            console.log(error);
        });
    };

    const getComparisonData = () => {
        const trimIds = vehicleInformation?.map(vehicle => vehicle?.trimId).filter(id => id !== '');
        getComparisonReport(trimIds)
            .then((data) => {
                setComparisonData(data?.comparisonData);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getInitialTrims = () => {
        getModelTrims(initialModelId)
            .then((data) => {
                setInitialTrimData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const setInitialToyotaData = () => {
        const formattedTrimData = initialTrimData?.map(trim => (
            {
                label: trim.trimName,
                trimId: trim.trimId,
                imageUrl: trim.imageUrl
            }
        ))

        updateMake(0, 'Toyota');
        updateModel(0, initialModelId, formattedModelName, initialYear);
        updateTrim(0, formattedTrimData[0]?.trimId, formattedTrimData[0]?.label, formattedTrimData[0]?.imageUrl);
    };

    const setFirstCompetitorData = (competitorData) => {
        const modelId = getModelId(competitorData?.makeName, competitorData?.modelName, competitorData?.modelYear);

        updateMake(1, competitorData?.makeName);
        updateModel(1, modelId, competitorData?.modelName, competitorData?.modelYear);
        updateTrim(1, competitorData?.trimID, competitorData?.trimName, competitorData?.imageURL);
    };

    const updateMake = (vehicleNum, make) => {
        let vehicleInformationCopy = vehicleInformation.slice();
        vehicleInformationCopy[vehicleNum].make = make;
        vehicleInformationCopy[vehicleNum].modelOptions = processModels(makeList?.filter(item => item.makeName === make)[0]);
        vehicleInformationCopy[vehicleNum].modelId = '';
        vehicleInformationCopy[vehicleNum].modelName = '';
        vehicleInformationCopy[vehicleNum].trimName = '';
        vehicleInformationCopy[vehicleNum].trimId = '';
        vehicleInformationCopy[vehicleNum].year = '';
        vehicleInformationCopy[vehicleNum].trimOptions = [];
        setVehicleInformation(vehicleInformationCopy);
    };

    const updateModel = (vehicleNum, modelId, modelName, year) => {
        let loadingTrimsCopy = loadingTrims.slice();
        loadingTrimsCopy[vehicleNum] = true;
        setLoadingTrims(loadingTrimsCopy);
        let vehicleInformationCopy = vehicleInformation.slice();
        vehicleInformationCopy[vehicleNum].modelId = modelId;
        vehicleInformationCopy[vehicleNum].modelName = modelName;
        vehicleInformationCopy[vehicleNum].year = year;
        vehicleInformationCopy[vehicleNum].trimName = '';
        vehicleInformationCopy[vehicleNum].trimId = '';

        getModelTrims(modelId)
            .then((data) => {
                const formattedTrimData = data?.map(trim => (
                    {
                        label: trim.trimName,
                        trimId: trim.trimId,
                        imageUrl: trim.imageUrl
                    }
                ))
                vehicleInformationCopy[vehicleNum].trimOptions = formattedTrimData;
                setVehicleInformation(vehicleInformationCopy);

                loadingTrimsCopy = loadingTrims.slice();
                loadingTrimsCopy[vehicleNum] = false;
                setLoadingTrims(loadingTrimsCopy);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateTrim = (vehicleNum, trimId, trimName, imageUrl) => {
        let vehicleInformationCopy = vehicleInformation.slice();
        vehicleInformationCopy[vehicleNum].trimId = trimId;
        vehicleInformationCopy[vehicleNum].trimName = trimName;
        vehicleInformationCopy[vehicleNum].imageUrl = imageUrl;
        setVehicleInformation(vehicleInformationCopy);
        getComparisonData();
        
        setVehicleOneTrim(trimName);
    };

    const processModels = (data) => {
         
        const modelOptions = data?.modelNameList?.map(model => {
            return model.modelYearList?.map(modelYear => {
                return (
                    {
                        model: model.modelName,
                        year: modelYear.modelYear,
                        modelId: modelYear.modelId.modelId
                    }
                )
            });
        }).flat();
        return modelOptions;
    };

    const processLabelChange = (header) => {
        if(header === 'MSRP') {
            return (
                <span> 
                    Base MSRP <sup className="disclaimer-link" onClick={(event) => {event.stopPropagation(); disclaimerComponentRef.current.onOpenModal();}}>&dagger;</sup>
                </span>
            )
        } else {
            return tooltips[header] ? applyMarkdown(tooltips[header], disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData)
            : applyMarkdown(header, disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData);
        }
    };

    const processAccordionData = () => {
        return comparisonData?.map(item => (
            {
                outerHeader: item?.specCategoryName,
                innerAccordionData: item?.specCategoryData?.map(innerItem => (
                    {
                        innerHeader: processLabelChange(innerItem?.specName),
                        body: accordionDataRow(innerItem?.specData)
                    }
                ))

            }));
    };

    const accordionDataRow = (data) => {

        let processedData = [];
        let currentIndex = 0;
        for (const vehicle of vehicleInformation.slice(0, numComparisons)) {
            if(vehicle.trimId !== '') {
                processedData.push(data[currentIndex]?.specText);
                currentIndex++;
            } else {
                processedData.push(null);
            }
        }

        return (
            <div className="accordion-data-row">
                {processedData?.map(item => {
                    return (
                        <div className="item">
                            {item ?
                                <div className="data-value">
                                    {applyMarkdown(item, null, true)}
                                </div>
                                :
                                <div className="data-placeholder">
                                    {!isPrint ? 'Select Vehicle' : '-'}
                                </div>
                            }
                        </div>
                    )
                }
                )}
            </div>
        );
    };

    const handleShadowImage = (modelName) => {
        getVehicleCategory(modelName)
        .then((category) => {
            if(category === 'car') {
                    setShadowImage('genericCar.png');
            } else if(category === 'suv') {
                    setShadowImage('genericSUV.png');
            } else if(category === 'truck') {
                    setShadowImage('genericTruck.png');
                } else {
                    setShadowImage('genericCar.png');
                }
            })
    };

    // fixed disclaimer due addBaseMSRPDisclaimer prop
    const disclaimers = {'✝':`Base MSRP excludes manufacturer, distributor and dealer options, taxes, title and license and dealer fees and charges. 
        Also excludes the Delivery, Processing and Handling Fee of $1,095 for Cars (GR 86, Avalon, Avalon HV, Camry, Camry HV, Corolla, Corolla HV, Corolla HB, 
        GR Corolla, Mirai, Prius, Prius Prime, GR Supra, Toyota Crown), $1,335 for SUV/Van/Small Truck (4Runner, Corolla Cross HV, C-HR, Highlander, Highlander HV, RAV4, 
        RAV4 HV, RAV4 Prime, Sienna, Tacoma, Venza, BZ4X), [$1,595 for Large SUVs (Sequoia), and $1,795 for Large Truck (Tundra and Tundra Hybrid). (Historically, 
        vehicle manufacturers and distributors have charged a separate fee for processing, handling and delivering vehicles to dealerships. Toyota's charge for these 
        services is called the ""Delivery, Processing and Handling Fee"" and is based on the value of the processing, handling and delivery services Toyota provides as 
        well as Toyota's overall pricing structure and may be subject to change at any time. Toyota may make a profit on the Delivery, Processing and Handling Fee.) The 
        Delivery, Processing and Handling Fee in AL, AR, FL, GA, LA, MS, NC, OK, SC and TX will be higher. Dealer price will vary. `};

    return (
        jatoFailure ? 
            <div className="noResponse">3rd Party Comparison information currently unavailable.</div>
        :
            <div className="third-party-comparison-component">
                <ThirdPartyComparisonFilter
                    makeList={makeList}
                    vehicleInformation={vehicleInformation}
                    updateMake={updateMake}
                    updateModel={updateModel}
                    updateTrim={updateTrim}
                    numComparisons={numComparisons}
                    loadingTrims={loadingTrims}
                    firstLoadComplete={firstLoadComplete}
                    shadowImage={shadowImage}
                    analyticsData={{
                        page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
                        modelName: props?.model,
                        modelYear: props?.year
                    }}
                    setVehicleOneTrim={setVehicleOneTrim}
                />
                {isMobileView(window.innerWidth) && 
                    <div className="mobile-accordion-header">
                        <div className="header-vehicle-label">VEHICLE 1</div>
                        <div className="header-vehicle-label">VEHICLE 2</div>
                    </div>
                }

                {comparisonData ?
                    <NestedAccordion
                        expandCollapseAll
                        setShouldCollapseAllChildren={setShouldCollapseAllChildren}
                        shouldCollapseAllChildren={shouldCollapseAllChildren}
                        setShouldExpandAllChildren={setShouldExpandAllChildren}
                        shouldExpandAllChildren={shouldExpandAllChildren}
                        data={processAccordionData()}
                        outerAccordionClassName={'vehicle-page-accordion'}
                        innerTitleStyling={props?.innerTitleStyling}
                        outerTitleStyling={props?.outerTitleStyling}
                        outerAnalyticsClick={(term, isExpand) => {
                            accordionClick({
                                page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
                                term: term,
                                isExpand: isExpand,
                                modelName: props.model,
                                modelYear: props.year,
                                isNested: false,
                                trim: vehicleOneTrim
                            });
                        }}
                        innerAnalyticsClick={(term, isExpand, _, parentTitle) => {
                            accordionClick({
                                page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
                                term: term,
                                isExpand: isExpand,
                                modelName: props.model,
                                modelYear: props.year,
                                isNested: true,
                                trim: vehicleOneTrim,
                                positionTitle: parentTitle
                            });
                        }}
                    />
                :
                    <Skeleton height={45} count={8}/>}

                {disclaimers && <div className="disclaimers-footnote" id="disclaimers-footnote-print-only">
                    <div className='disclaimers-title'>Disclaimers</div>
                    <p>{applyMarkdown(Object.keys(disclaimers).map((key) => `<b>${key}.</b>&nbsp;<text>${disclaimers[key]}</text>`).join('&nbsp;'))}</p>
                </div>}
                
                <DisclaimersComponent
                    ref={disclaimerComponentRef}
                    addBaseMSRPDisclaimer={true}
                />
            </div>
    );
};